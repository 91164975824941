import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Grid,
  Typography,
  CardContent,
  Card,
  Avatar,
  CircularProgress,
  IconButton,
  MenuItem,
  ListItemIcon,
  Menu,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

import 'firebase/firestore';
import firebase from 'firebase/app';
import { format } from 'date-fns';
import { RiDeleteBin6Line, RiMapPin2Fill } from 'react-icons/ri';
import Carousel from './Carousel';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import defaultImage from '../../assets/default-img.png';
import Navigate from '../home/Navigate';
import { HiDotsVertical } from 'react-icons/hi';
import { useTypedSelector } from '../../redux/store';
import { FiEdit } from 'react-icons/fi';
import { useSnackbar, OptionsObject as SnackbarOptions } from 'notistack';
import { firestore } from '../../utils/firebase-utils';

interface CustomTimestamp {
  seconds: number;
  nanoseconds: number;
}

const snackbarCommonOpt: SnackbarOptions = {
  autoHideDuration: 3000,
  preventDuplicate: true,
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '97%',
      margin: '0 auto',
      position: 'relative',
      top: '40px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    menu: {
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
    },

    icon: {
      color: '#42526E',
      minWidth: '30px',
    },

    menuItem: {
      color: '#42526E',
      fontWeight: 600,
      '&:hover': {
        color: 'red',
        '& .MuiListItemIcon-root': {
          color: 'red',
        },
      },
    },

    responsiveCard: {
      overflow: 'hidden',
      marginBottom: '50px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0px',
      },
    },
  }),
);

const ExpandableText = ({ content }: { content: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxCharacters = 350;
  const textRef = useRef<HTMLDivElement | null>(null);

  const shouldShowMore = content?.length > maxCharacters;

  const handleToggle = () => {
    if (isExpanded && textRef?.current) {
      window.scrollTo({
        top: 200,
        behavior: 'smooth',
      });
    }
    setIsExpanded(!isExpanded);
  };
  
  const jsonLdSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Antique Events and Auctions',
    description:
      "'Stay updated on upcoming antique events and auctions at Antiquesmart. Discover rare items and unique finds at our exclusive events.'",
    url: 'https://antiquesmart.com/events',
  };

  useEffect(() => {
    // Update title
    document.title = 'Antique Events and Auctions';

    // Keywords meta tag
    const keywordsContent =
      'Antiquesmart Events, Antique Auctions, Vintage Auctions, Online Antique Events, Antique Shows and Fairs, Antique Marketplace Auctions, Rare Item Auctions, Antique Collectors Events, Live Auctions for Antiques, Online Bidding for Antiques, Upcoming Antique Events, Auction Listings, Antique Exhibitions, Vintage Collectible Auctions, Special Antique Sales';
    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (!metaKeywords) {
      metaKeywords = document.createElement('meta');
      metaKeywords.setAttribute('name', 'keywords');
      document.head.appendChild(metaKeywords);
    }
    metaKeywords.setAttribute('content', keywordsContent);

    // Description meta tag
    const descriptionContent =
      "'Stay updated on upcoming antique events and auctions at Antiquesmart. Discover rare items and unique finds at our exclusive events.'";
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.setAttribute('name', 'description');
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute('content', descriptionContent);

    // Author meta tag
    const authorContent = 'Antiquesmart';
    let metaAuthor = document.querySelector('meta[name="author"]');
    if (!metaAuthor) {
      metaAuthor = document.createElement('meta');
      metaAuthor.setAttribute('name', 'author');
      document.head.appendChild(metaAuthor);
    }
    metaAuthor.setAttribute('content', authorContent);

    // Open Graph meta tags
    const ogProperties = [
      {
        property: 'og:title',
        content: 'Antique Events and Auctions | Antiquesmart',
      },
      { property: 'og:description', content: descriptionContent },
      {
        property: 'og:image',
        content: 'https://antiquesmart.com/events-image.jpg',
      },
      { property: 'og:url', content: 'https://antiquesmart.com/events' },
      { property: 'og:type', content: 'website' },
    ];

    ogProperties.forEach(({ property, content }) => {
      let metaTag = document.querySelector(`meta[property="${property}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('property', property);
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    });

    // Twitter meta tags
    const twitterProperties = [
      {
        name: 'twitter:title',
        content: 'Antique Events and Auctions | Antiquesmart',
      },
      {
        name: 'twitter:description',
        content:
          "'Stay updated on upcoming antique events and auctions at Antiquesmart. Discover rare items and unique finds at our exclusive events.'",
      },
      {
        name: 'twitter:image',
        content: 'https://antiquesmart.com/events-image.jpg',
      },
      { name: 'twitter:card', content: 'summary_large_image' },
    ];

    twitterProperties.forEach(({ name, content }) => {
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('name', name);
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    });

    // json-ld Schema
    const jsonLdScript = document.querySelector(
      'script[type="application/ld+json"]',
    );
    if (jsonLdScript) {
      jsonLdScript.innerHTML = JSON.stringify(jsonLdSchema);
    } else {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(jsonLdSchema);
      document.head.appendChild(script);
    }
  }, []);

  return (
    <Box>
      <Typography
        variant="body2"
        style={{
          color: '#828282',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '31px',
        }}
        ref={textRef}
      >
        {isExpanded
          ? content
          : content?.slice(0, maxCharacters) + (shouldShowMore ? '' : '')}
        {shouldShowMore && (
          <Button
            style={{
              fontSize: '14px',
              padding: 0,
              textTransform: 'none',

              color: '#AB7254',
            }}
            onClick={handleToggle}
          >
            {isExpanded ? 'Less' : '...More'}
          </Button>
        )}
      </Typography>
    </Box>
  );
};

const EventDetails = () => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [eventData, setEventData] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const { uid } = useTypedSelector(state => state.user);
  const [eventToDelete, setEventToDelete] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchEventData = async () => {
      setIsLoading(true);
      try {
        const doc = await firebase
          .firestore()
          .collection('events')
          .doc(id)
          .get();
        if (doc.exists) {
          setEventData(doc.data());
        } else {
          console.error('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) fetchEventData();
  }, [id]);

  const convertTimestampToDate = (timestamp: CustomTimestamp): Date => {
    return new Date(timestamp.seconds * 1000);
  };

  const formatAddress = (address: {
    city?: string;
    state?: string;
    country?: string;
    addressLineOne?: string;
  }): string => {
    if (!address) return 'No address found';

    const { city, state, country, addressLineOne } = address;
    const addressParts = [city, state, country].filter(Boolean).join(', ');

    if (addressParts) {
      return addressParts;
    } else if (addressLineOne) {
      return addressLineOne;
    } else {
      return 'No address found';
    }
  };

  const formatCreatedAt = (
    createdAt: firebase.firestore.Timestamp | null,
  ): string => {
    if (!createdAt) return 'Invalid Date';

    const createdAtDate = createdAt.toDate();
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;

    if (now.getTime() - createdAtDate.getTime() < oneDay) {
      return `Today at ${format(createdAtDate, 'hh:mm a')}`;
    } else if (now.getTime() - createdAtDate.getTime() < oneWeek) {
      return `Last ${format(createdAtDate, 'EEEE')} at ${format(
        createdAtDate,
        'hh:mm a',
      )}`;
    } else {
      return format(createdAtDate, 'MMM d, yyyy hh:mm a');
    }
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    eventId: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedEvent(eventId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedEvent(null);
  };

  const handleEdit = (id: string) => {
    history.push(`/list-event/${id}`);
    handleMenuClose();
  };

  const handleDeleteEvent = async () => {
    if (!eventToDelete) return;
    setIsLoading(true);
    try {
      await firestore.collection('events').doc(eventToDelete).delete();

      setDeleteDialogOpen(false);
      history.push('/events');
      handleMenuClose();
      enqueueSnackbar('Event deleted successfully!', {
        variant: 'success',
        ...snackbarCommonOpt,
      });
    } catch (error) {
      console.error('Error deleting event:', error);
      enqueueSnackbar('Failed to delete event. Please try again.', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  const confirmDelete = (eventId: string) => {
    setEventToDelete(eventId);
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBjFEh8BnuyHRNju8Z7gmnO6PuZJ0x-41w',
  });

  const handleMapClick = () => {
    const { lat, lng } = eventData?.address;
    window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank');
  };

  const containerStyle = {
    width: '100%',
    height: '300px',
    borderRadius: '10px',
  };

  const formatPrice = (price: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };
  
  return (
    <div
      style={{
        backgroundColor: 'rgb(176 196 222 / 17%)',
        width: '100%',
      }}
    >
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} md={8}>
          <Card className={classes.responsiveCard}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                mb={2}
                style={{
                  background: '#fff',
                  padding: '20px 10px 0 10px',
                  cursor: 'pointer',
                }}
                onClick={() => history.push(`/profile/${eventData?.uid}`)}
              >
                <Avatar
                  src={eventData?.profilePicture || 'default-avatar'}
                  alt={eventData?.userName}
                  style={{ marginRight: '10px' }}
                />
                <Box>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 600, color: '#000000' }}
                  >
                    {eventData?.userName && eventData?.userName.trim() !== ''
                      ? eventData?.userName
                      : 'No User Name Available'}
                  </Typography>

                  <Typography variant="subtitle2" color="textSecondary">
                    {formatCreatedAt(eventData?.createdAt)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                {eventData?.uid === uid && (
                  <IconButton onClick={e => handleMenuOpen(e, eventData?.id)}>
                    <HiDotsVertical />
                  </IconButton>
                )}
              </Box>
            </Box>
            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedEvent === eventData?.id}
              onClose={handleMenuClose}
              classes={{ paper: classes.menu }}
            >
              <MenuItem
                onClick={() => handleEdit(eventData?.id)}
                className={classes.menuItem}
              >
                <ListItemIcon className={classes.icon}>
                  <FiEdit />
                </ListItemIcon>
                <Typography variant="inherit" style={{ fontWeight: 600 }}>
                  Edit
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => confirmDelete(eventData?.id)}
                className={classes.menuItem}
              >
                <ListItemIcon className={classes.icon}>
                  <RiDeleteBin6Line />
                </ListItemIcon>
                <Typography variant="inherit" style={{ fontWeight: 600 }}>
                  Delete
                </Typography>
              </MenuItem>
            </Menu>

            <Box position="relative"
             style={{
              height: xsDown ? 'auto' : 'calc(100% * 3 / 4)',
              }}
            >
              <Carousel images={eventData?.images || [defaultImage]} />
            </Box>

            <CardContent>
              <Box
                style={{
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    style={{
                      color: '#AB7254',
                      fontWeight: 800,
                      fontSize: '20px',
                    }}
                  >
                     {eventData?.eventCost === 0 ? 'Free' : `$${formatPrice(eventData?.eventCost)}`}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: '#1A202C',
                      fontWeight: 700,
                      fontSize: '20px',
                    }}
                  >
                    {eventData?.title}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography
                  variant="body1"
                  style={{
                    color: '#24222CE5',
                    fontWeight: 700,
                    marginBottom: '.6rem',
                  }}
                >
                  About event
                </Typography>
                <ExpandableText content={eventData?.content} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            style={{
              background: '#fff',
              padding: '20px',
              marginBottom: '50px',
            }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: 700, color: '#000000' }}
            >
              Date
            </Typography>
            <Box
              style={{
                display: 'flex',
                marginTop: '10px',
              }}
            >
              <Box
                style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '12px',
                  marginRight: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgb(171 114 84 / 16%)',
                }}
              >
                <img
                  src={require('../../assets/Icons/Calendar.png')}
                  alt="Calendar"
                  width="30px"
                  height="30px"
                />
              </Box>

              <Box>
                {eventData?.event_date && eventData?.event_end_date ? (
                  <>
                    <Typography
                      style={{
                        color: '#120D26',
                        fontWeight: 500,
                        fontSize: '14px',
                      }}
                    >
                      {format(
                        convertTimestampToDate(eventData?.event_date),
                        'd MMMM yyyy',
                      )}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: '#747688', fontSize: '12px' }}
                    >
                      {`${format(
                        convertTimestampToDate(eventData?.event_date),
                        'hh:mm a',
                      )} - ${format(
                        convertTimestampToDate(eventData?.event_end_date),
                        'hh:mm a',
                      )}`}
                    </Typography>
                  </>
                ) : (
                  'Invalid event date'
                )}
              </Box>
            </Box>
            <Box>
              <Typography
                variant="body1"
                style={{ fontWeight: 700, color: '#000000', marginTop: '18px' }}
              >
                Location
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '13px',
                }}
              >
                <RiMapPin2Fill
                  style={{
                    color: '#AB7254',
                    marginRight: '10px',
                    fontSize: '25px',
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ color: '#747688', fontSize: '12px' }}
                >
                  {formatAddress(eventData?.address)}
                </Typography>
              </Box>
              <Box
                style={{ marginTop: '15px', cursor: 'pointer' }}
                onClick={handleMapClick}
              >
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{
                      lat: eventData?.address?.lat,
                      lng: eventData?.address?.lng,
                    }}
                    zoom={15}
                    options={{ disableDefaultUI: true }}
                  >
                    <Marker
                      position={{
                        lat: eventData?.address?.lat,
                        lng: eventData?.address?.lng,
                      }}
                    />
                  </GoogleMap>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteEvent} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading && (
        <Box
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box style={{ position: 'relative', top: '-25px', overflow: 'hidden' }}>
        <Navigate />
      </Box>
    </div>
  );
};

export default EventDetails;
