import React, { useState } from 'react';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Button,
  Typography,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { enUS } from 'date-fns/locale';
import moment, { Moment } from 'moment';
import { useSnackbar, OptionsObject as SnackbarOptions } from 'notistack';
import '../style.css';

const snackbarCommonOpt: SnackbarOptions = {
  autoHideDuration: 3000,
  preventDuplicate: true,
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
};

interface FilterByDateProps {
  onClose: () => void;
  onApply: (dates: { from: Moment | null; to: Moment | null }) => void;
}

const FilterByDate: React.FC<FilterByDateProps> = ({ onClose, onApply }) => {
  const theme = useTheme();

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();

  // State to manage selected dates
  const [selectedFrom, setSelectedFrom] = useState<Moment | null>(null);
  const [selectedTo, setSelectedTo] = useState<Moment | null>(null);

  const handleFilter = () => {
    // Normalize values to Moment objects
    const from = moment(selectedFrom);
    const to = moment(selectedTo);

    // Check if both dates are selected
    if (!selectedFrom || !selectedTo) {
      enqueueSnackbar("Both 'From' and 'To' dates must be selected.", {
        variant: 'error',
        ...snackbarCommonOpt,
      });
      return;
    }

    // Check if 'From' date is later than 'To' date
    if (from.isAfter(to)) {
      enqueueSnackbar(
        "'From' date cannot be later than the 'To' date. Please select a valid range.",
        {
          variant: 'error',
          ...snackbarCommonOpt,
        },
      );
      return;
    }

    // Check for invalid year (e.g., 2027 or later)
    if (from.year() > 2025) {
      enqueueSnackbar(
        'Dates cannot be in an invalid year, such as 2027 or later.',
        {
          variant: 'error',
          ...snackbarCommonOpt,
        },
      );
      return;
    }
    
     // Check for invalid year (e.g., 2027 or later)
     if (to.year() > 2025) {
        enqueueSnackbar(
          'Dates cannot be in an invalid year, such as 2027 or later.',
          {
            variant: 'error',
            ...snackbarCommonOpt,
          },
        );
        return;
      }

      
      
    // Ensure valid day/month within the same year
    if (
      from.year() === to.year() &&
      (from.month() > to.month() ||
        (from.month() === to.month() && from.date() > to.date()))
    ) {
      enqueueSnackbar("'From' date cannot be later than the 'To' date.", {
        variant: 'error',
        ...snackbarCommonOpt,
      });
      return;
    }

    // If all validations pass
    enqueueSnackbar('Filter applied successfully!', {
      variant: 'success',
      ...snackbarCommonOpt,
    });
    
    // Pass selected dates to the parent
    onApply({ from: from, to: to });
    onClose(); // Close the filter box
  };

  const handleClearFilter = () => {
    setSelectedFrom(null);
    setSelectedTo(null);
    
    onApply({ from: null, to: null });
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: '54px',
          right: '0px',
          padding: '20px',
          paddingTop: '0px',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 999,
          width: mdDown ? '300px' : '400px',
          borderRadius: '10px',
        }}
      >
        <Box
          style={{
            color: '#00000094',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            cursor: 'pointer',
            marginTop: '10px',
          }}
        >
          <Box
            style={{
              position: 'relative',
              right: '-18px',
              display: 'inline-flex',
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ color: '#161616', fontWeight: 500 }}
          >
            Filter By
          </Typography>

          <Typography
            variant="subtitle2"
            style={{
              color: '#D28B53',
              fontWeight: 600,
              cursor: 'pointer',
            }}
            onClick={handleClearFilter}
          >
            Clear Filter
          </Typography>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enUS}>
                <DatePicker
                  label="From"
                  value={selectedFrom}
                  onChange={date => {
                    setSelectedFrom(date);
                  }}
                  format="MM/dd/yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enUS}>
                <DatePicker
                  label="To"
                  value={selectedTo}
                  onChange={date => {
                    setSelectedTo(date);
                  }}
                  format="MM/dd/yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#D28B53',
              color: theme.palette.common.white,
              width: '100%',
              borderRadius: '20px',
              textTransform: 'capitalize',
            }}
            onClick={handleFilter}
          >
            Filter
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FilterByDate;
