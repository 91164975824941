import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Container,
  useMediaQuery,
  useTheme,
  TextField,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Button,
  ButtonGroup,
  alpha,
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import Post from './components/Post';
import { useTypedSelector } from '../../redux/store';
import {
  getPostsThunk,
  addPostThunk,
  searchPostsThunk,
} from '../../redux/actions/posts';
import { useDispatch } from 'react-redux';
import PostModel from '../../models/[new]post';
import CustomButton from '../../components/buttons/Button';
import { useSnackbar } from 'notistack';
import comonSnackOpts from '../../utils/snackbar';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CircularLoader from '../../components/CircularProgressBar/CircularLoader';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import StoreButtons from '../../components/Breadcrumb/StoreButtons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '70%',
      margin: '3rem auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    imgsContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '.1rem 0 ',
    },
    commentImg: {
      width: '100px',
      height: '100px',
      marginRight: '10px',
      borderRadius: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '55px',
        height: '55px',
      },
    },
    btnsWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1rem 2rem',
    },
    imgBtn: {
      width: '10.8125rem',
      height: '2.125rem',
      color: '#898989',
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      borderRadius: '.25rem',
      border: '1px solid #EAEAEA',
    },
    postBtn: {
      width: '5rem',
      height: '2.125rem',
      fontSize: '0.75rem',
      fontFamily: 'Poppins',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '2rem 0',
      [theme.breakpoints.down('xs')]: {
        order: 2,
        padding: '.8rem 0 2rem',
      },
    },
    
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '10px',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
      },
    },
    
    txt: {
      fontWeight: 'bold',
      fontSize: '1.75rem',
      fontFamily: 'Arial',
      marginLeft: '1rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.4rem',
      },
    },
    brownDiv: {
      width: '0.375rem',
      height: '1.75rem',
      borderRadius: '0.625rem',
      background: '#D28B53',
    },

    fitContentHeight: {
      flexDirection: 'column',
      margin: 'auto',
    },
    descriptionField: {
      width: '100%',
      whiteSpace: 'pre-line',
      border: 'none',
    },
    postImageFeaturedStar: {
      position: 'absolute',
      top: -15,
      right: -10,
      color: '#B87A4E',
      opacity: 1,
    },
    form: {
      display: 'contents',
    },
    input: {
      backgroundColor: '#F9F9F9',
      borderRadius: '0.3125rem 0rem 0rem 0.3125rem',
      padding: theme.spacing(1, 1, 1, 1),
      transition: theme.transitions.create('width'),
      height: '35px',
      border: '1px solid #E6E6E6;',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchBtn: {
      borderRadius: '0rem 0.3125rem 0.3125rem 0rem',
      backgroundColor: '#FDBB84',
      height: '35px',
    },
    no_results: {
      fontSize: '24px',
      color: '#CF8A50',
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '20px',
    },
  }),
);
interface Props {
  className?: string;
}

const Community = ({ className }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const { postsList } = useTypedSelector(state => state.postsReducer);
  const user = useTypedSelector(state => state.user);
  const { uid } = useTypedSelector(state => state.user);
  const submitLoading = useTypedSelector(state => state.UI.loaders.ADD_POST);
  const getPostsLoading = useTypedSelector(
    state => state.UI.loaders.SEARCH_POSTS,
  );
  const [postContent, setPostContent] = useState('');
  const [imageFiles, setImageFiles] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = () => {
    dispatch(getPostsThunk());
  };

  const handleImageChange = (e: any) => {
    const selectedFiles = Array.from(e.target.files);
    if (
      imageFiles?.length + selectedFiles?.length > 5 ||
      selectedFiles?.length > 5
    ) {
      enqueueSnackbar("Images can't be more than 5", {
        variant: 'error',
        ...comonSnackOpts,
      });
      return;
    }
    setImageFiles((prevFiles: any) => [...prevFiles, ...selectedFiles]);
  };

  const handleCameraClick = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = (
    image: Blob | MediaSource,
    index: React.Key | null | undefined,
  ) => {
    if (!image) {
      return;
    }
    const removedFromImages = imageFiles?.filter((item: any) => {
      return item != image;
    });
    setImageFiles(removedFromImages);
  };

  const submitPost = async () => {
    // Check if the value contains only spaces
    const containsOnlySpaces = /^\s+$/.test(postContent);

    if (containsOnlySpaces) {
      enqueueSnackbar(
        'Please ensure that the content does not consist solely of spaces.',
        {
          variant: 'error',
          ...comonSnackOpts,
        },
      );
    } else {
      try {
        await dispatch(
          addPostThunk({
            images: imageFiles,
            content: postContent,
            likesCount: 0,
            commentsCount: 0,
            uid: user?.uid,
            profilePicture: user?.profilePicture?.url,
            userDisplayName:
              (user?.userType === 'seller' && user
                ? user?.sellerData?.sellerName
                : user?.userType === 'shop' && user
                ? user?.shop?.shopName
                : user?.userType === 'buyer' && user
                ? user?.firstName
                : '') ?? '',
          }),
        );
        fetchPosts();
        enqueueSnackbar('Your post has been successfully added.', {
          variant: 'success',
          ...comonSnackOpts,
        });
        setPostContent('');
        setImageFiles([]);
      } catch (error) {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          ...comonSnackOpts,
        });
      }
    }
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(searchPostsThunk(searchQuery));
  };

  useEffect(() => {
    if (searchQuery.trim() === '') {
      fetchPosts();
    }
  }, [searchQuery]);

  const jsonLdSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Antique community',
    description:
      "'Join Antiquesmart's community platform for antique buyers and sellers. Share posts, discover rare items, and connect with other collectors.'",
    url: 'https://antiquesmart.com/community',
  };

  useEffect(() => {
    // Update title
    document.title = 'Antique Sellers | Antiquesmart<';

    // Keywords meta tag
    const keywordsContent =
      'Antiquesmart Community, Antique Enthusiast Community, Vintage Collectors Forum, Antique Marketplace Community, Connect with Antique Collectors, Antique Buying and Selling Community, Collectible Enthusiasts Network, Rare Antiques Discussion, Antique Dealer Community, Online Antique Groups, Vintage Community Hub, Antique Advice and Tips, Antique Collectors Network, Antique Restoration Community';
    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (!metaKeywords) {
      metaKeywords = document.createElement('meta');
      metaKeywords.setAttribute('name', 'keywords');
      document.head.appendChild(metaKeywords);
    }
    metaKeywords.setAttribute('content', keywordsContent);

    // Description meta tag
    const descriptionContent =
      "'Join Antiquesmart's community platform for antique buyers and sellers. Share posts, discover rare items, and connect with other collectors.'";
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.setAttribute('name', 'description');
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute('content', descriptionContent);

    // Author meta tag
    const authorContent = 'Antiquesmart';
    let metaAuthor = document.querySelector('meta[name="author"]');
    if (!metaAuthor) {
      metaAuthor = document.createElement('meta');
      metaAuthor.setAttribute('name', 'author');
      document.head.appendChild(metaAuthor);
    }
    metaAuthor.setAttribute('content', authorContent);

    // Open Graph meta tags
    const ogProperties = [
      {
        property: 'og:title',
        content: 'Community | Antiquesmart',
      },
      { property: 'og:description', content: descriptionContent },
      {
        property: 'og:image',
        content: 'https://antiquesmart.com/community-image.jpg',
      },
      { property: 'og:url', content: 'https://antiquesmart.com/community' },
      { property: 'og:type', content: 'website' },
    ];

    ogProperties.forEach(({ property, content }) => {
      let metaTag = document.querySelector(`meta[property="${property}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('property', property);
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    });

    // Twitter meta tags
    const twitterProperties = [
      {
        name: 'twitter:title',
        content: 'Community | Antiquesmart',
      },
      {
        name: 'twitter:description',
        content:
          "'Join Antiquesmart's community platform for antique buyers and sellers. Share posts, discover rare items, and connect with other collectors.'",
      },
      {
        name: 'twitter:image',
        content: 'https://antiquesmart.com/community-image.jpg',
      },
      { name: 'twitter:card', content: 'summary_large_image' },
    ];

    twitterProperties.forEach(({ name, content }) => {
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('name', name);
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    });

    // json-ld Schema
    const jsonLdScript = document.querySelector(
      'script[type="application/ld+json"]',
    );
    if (jsonLdScript) {
      jsonLdScript.innerHTML = JSON.stringify(jsonLdSchema);
    } else {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(jsonLdSchema);
      document.head.appendChild(script);
    }
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Community | Antiquesmart </title>
        <meta name="description" content="Connect with fellow antique collectors and enthusiasts in the Antiquesmart Community. Share your passion for timeless treasures with others." />
        
      </Helmet>

      <Box
        
        className={clsx(classes.headerContainer)}
      >
        <Box className={clsx(classes.titleContainer)}>
          <span className={clsx(classes.brownDiv)}></span>
          <h1 className={clsx(classes.txt)}>Antiquesmart Community</h1>
        </Box>
        <StoreButtons />
      </Box>
      <div className={classes.search}>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          style={{ height: '35px', display: 'flex', justifyContent: 'center' }}
        >
          <form ref={formRef} className={classes.form} onSubmit={handleSearch}>
            <div style={{ width: xsDown ? '100%' : '60%' }}>
              <input
                className={classes.input}
                placeholder="Search for a post"
                type="search"
                onChange={e => setSearchQuery(e.target.value)}
                value={searchQuery}
              />
            </div>
            <Button className={classes.searchBtn} type="submit">
              <SearchIcon />
            </Button>
          </form>
        </ButtonGroup>
      </div>
      <Grid
        className={clsx(classes.fitContentHeight)}
        container
        alignItems="center"
      >
        {uid && (
          <Card className={classes.root}>
            <CardContent>
              <TextField
                fullWidth
                multiline
                className={classes.descriptionField}
                rows={6}
                name="description"
                placeholder="Type something here.."
                value={postContent}
                onChange={e => setPostContent(e.target.value)}
              />
            </CardContent>
            <div className={classes.imgsContainer}>
              <input
                type="file"
                id="imageUpload"
                name="imageUpload"
                onChange={handleImageChange}
                multiple
                accept="image/*"
                ref={fileInputRef}
                hidden
              />
              <div style={{ display: 'flex' }}>
                {imageFiles?.map(
                  (
                    file: Blob | MediaSource,
                    index: React.Key | null | undefined,
                  ) => (
                    <div
                      role="button"
                      tabIndex={0}
                      style={{ position: 'relative' }}
                      key={`img : ${index}`}
                    >
                      <IconButton
                        onClick={() => handleRemoveImage(file, index)}
                        className={classes.postImageFeaturedStar}
                        style={{ zIndex: '9999' }}
                      >
                        <BackspaceIcon />
                      </IconButton>
                      <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt={`Preview ${index}`}
                        className={classes.commentImg}
                      />
                    </div>
                  ),
                )}
              </div>
            </div>
            <CardActions>
              <div className={classes.btnsWrapper}>
                <Button className={classes.imgBtn} onClick={handleCameraClick}>
                  <NoteAddOutlinedIcon />
                  Add Picture
                </Button>

                <CustomButton
                  loading={submitLoading}
                  onClick={submitPost}
                  variant="contained"
                  color="secondary"
                  disabled={
                    postContent === '' && imageFiles?.length === 0
                      ? true
                      : false
                  }
                  className={classes.postBtn}
                >
                  POST
                </CustomButton>
              </div>
            </CardActions>
          </Card>
        )}

        {postsList && postsList?.length > 0 ? (
          postsList.map((post: PostModel) => {
            return <Post key={post.id} post={post} searchQuery={searchQuery} />;
          })
        ) : (
          <div className={classes.no_results}>No search results found</div>
        )}
        {getPostsLoading && <CircularLoader.PageLoader />}
      </Grid>
    </Container>
  );
};

export default Community;
